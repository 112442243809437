$darkGrey: #16181d;
$lightGrey: #505560;
$sidebarText: #9ca0aa;
$activeStep: #0b9f57;

.create_location_admin {
  display: flex;
  width: calc(100% + 244px);
  margin-left: -244px;
  position: relative;
  z-index: 99;

  .icon_btn {
    padding-right: 16px;
  }

  .location_sidebar {
    width: 292px;
    background-color: $darkGrey;
    height: 100vh;
    position: fixed;
    z-index: 9;
    padding: 32px 0;
    color: #fff;

    h3 {
      color: #fff;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 32px;
      padding: 0 32px;
    }

    .no_bg {
      padding: 8px;
      margin-left: -8px;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin: 0 32px;
    }
  }

  .ant-steps {
    width: 100%;
    padding-top: 0;

    &.ant-steps-vertical {
      .ant-steps-item {
        text-align: left;

        .ant-steps-item-container {
          margin-left: 0;
        }

        .ant-steps-item-tail {
          top: 0;
          left: 12px;
          width: 2px;
          height: 100%;
          padding: 32px 0 6px;

          &:after {
            background-color: $lightGrey;
            width: 2px;
          }
        }

        .ant-steps-item-icon {
          background-color: $lightGrey;
          width: 26px;
          height: 26px;
          border: 0;
          line-height: 26px;
          margin-right: 16px;

          .ant-steps-icon {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .ant-steps-item-content {
          min-height: 64px;
          .ant-steps-item-title {
            color: $sidebarText;
            font-size: 14px;
            line-height: 26px;
            font-weight: 400;
          }
        }

        &.ant-steps-item-finish,
        &.ant-steps-item-active {
          .ant-steps-item-tail {
            &:after {
              background-color: $activeStep;
            }
          }

          .ant-steps-item-icon {
            background-color: $activeStep;
          }

          .ant-steps-item-content {
            .ant-steps-item-title {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .steps-content {
    width: calc(100% - 292px);
    margin-left: 292px;

    .ant-form-item {
      input.ant-input {
        height: 36px;
      }
    }

    .ant-select-single {
      .ant-select-selector {
        border-radius: 8px;
        height: 36px;

        .ant-select-selection-search {
          padding: 0px 30px 0 10px;

          input {
            height: 36px;
          }
        }
      }

      .ant-select-selection-item {
        .option-label-item {
          line-height: 36px;
        }
      }
    }
  }

  .steps_wrapper {
    height: calc(100vh - 182px);
    overflow: auto;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 32px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #1a2d3f;
    }
  }

  .ant-select-single {
    .ant-select-selector {
      .ant-select-selection-placeholder {
        line-height: 34px;
      }
    }
  }
}

.loc_detail_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 64px 0 0 0;
  min-height: 100vh;
  margin: 0;

  &.admin_form4 {
    height: 100vh;

    &.full_width {
      .hotel_creation {
        width: 100%;
      }
      .loc_wrapper {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    .hotel_creation {
      padding: 0 !important;

      .floor_scroll_wrapper {
        height: calc(100vh - 270px);
      }

      .add_floor_btn {
        margin-left: 0;
        left: 0;
      }

      .floor_inline {
        padding: 0 32px 0 0;
      }

      .top_head_btn {
        padding: 0;
      }
      .floor_cards {
        padding-left: 0;
      }
    }
  }

  &.full_width {
    .loc_wrapper {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &.department_roles {
    .loc_wrapper {
      width: 812px;
    }
  }

  &.scheduling_select {
    .loc_wrapper {
      width: 708px;
    }

    .connect_ukg {
      h4 {
        margin-bottom: 8px;
      }
      .no_bg {
        margin-left: -8px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 24px;

        .ant-image {
          margin-left: 8px;
        }
      }
    }
  }

  .loc_wrapper {
    width: 604px;
    min-height: calc(100vh - 170px);
  }

  h2 {
    color: #16181d;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  p {
    color: #505560;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
  }

  .common_row {
    .ant-card {
      background-color: #fbfbfc;
      border: 0;
      border-radius: 16px;
    }

    .ant-card-body {
      padding: 32px;
    }

    .ant-card-meta {
      margin: 0;
    }

    .ant-card-meta-avatar {
      padding-right: 20px;
    }

    .ant-card-meta-title {
      color: #16181d;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .ant-card-meta-description {
      color: #686d79;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 0;
    }

    .black_icon_btn {
      margin: 24px 0 0 68px;
    }
  }

  .panel_linen_switch {
    display: flex;
    gap: 16px;
  
    h4 {
      color: #16181D;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
    }
  
    p {
      color: #686D79;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }

    .ant-form-item-label {
      justify-content: flex-start;
    }
  }
}

.location_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  position: sticky;
  bottom: 0;
  padding: 0px 40px 38px;
  left: 0;
  z-index: 9;

  > .ant-row {
    background: #f7f8f9;
    padding: 10px 20px;
    border-radius: 12px;
  }
}

.group_inputs {
  margin-bottom: 24px;
  .ant-form-item {
    margin-bottom: 0;
  }
}

.ant-select-arrow {
  z-index: 9;
}

.admin_location_listing {
  width: 100%;
  display: flex;
  flex-direction: column;

  .location-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 40px 40px 0 40px;

    @media (max-width: 1400px) {
      padding: 20px 20px 0 20px;
    }

    .header-title {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      .title {
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        color: #16181d;
        margin-bottom: 0;
      }
      .badge {
        padding: 6px;
        min-width: 32px;
        height: 32px;
        background: #f7f8f9;
        border-radius: 6px;
        margin-left: 16px;
        text-align: center;
      }
    }

    .header-search {
      display: flex;
      margin-bottom: 16px;

      .search-bar {
        width: 332px;
        height: 36px;
        padding: 8px 12px;
        border: 1px solid #e4e5e7;
        border-radius: 8px;
        background: #ffffff;
      }

      .ant-input-prefix {
        margin-right: 10px;
        color: #9ca0aa;
      }
    }

    .header-filter {
      width: 100%;
      .section_filter {
        display: flex;
      }
    }
  }

  .location-content {
    padding: 0px 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 1200px) {
      padding: 0px 20px;
    }

    .table-container {
      box-shadow: none;
      flex-grow: 1;
    }

    .user-avatar {
      margin-right: 10px;
      width: 28px;
      height: 28px;
    }

    .ant-table-container {
      table {
        > .ant-table-thead {
          > tr {
            th {
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }

        > .ant-table-tbody {
          > tr {
            td {
              padding: 14px 8px 12px;

              @media (max-width: 1400px) {
                padding: 14px 4px 12px;
              }
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }

        .ant-checkbox {
          width: 18px;
        }
      }
    }
  }

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: #0b9f57;
      }
    }
  }

  .ant-checkbox-checked {
    &::after {
      border: 1px solid #0b9f57;
    }

    .ant-checkbox-inner {
      background-color: #0b9f57;
      border-color: #0b9f57;
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      border-color: #0b9f57;

      &:after {
        background-color: #0b9f57;
      }
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #0b9f57;
  }

  .ant-pagination-item:hover,
  .ant-pagination-item-active {
    border-color: #0b9f57;
    a {
      color: #0b9f57;
    }
  }
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #0b9f57;
    color: #0b9f57;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #0b9f57;
  }
}

.upload_loc_image {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  border-radius: 16px;

  > span {
    width: 100%;
  }

  .ant-upload {
    &.ant-upload-drag {
      border: 0;
      border-radius: 16px !important;
      background: #fbfbfc !important;
      width: 100%;
      height: 164px;
      overflow: hidden;

      &:hover {
        background: #eee !important;
      }

      .ant-upload-drag-container {
        vertical-align: initial;
      }

      p.ant-upload-drag-icon {
        margin-top: 34px;
        margin-bottom: 16px;
      }

      .ant-upload-text {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
        color: $darkGrey;
        font-weight: 500;
      }

      .ant-upload-hint {
        color: $lightGrey;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 0;
      }

      .ant-upload-drag-image {
        width: 152px;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
        padding: 10px 0;

        .ant-image {
          height: 100%;

          img {
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
          }
        }
      }
    }

    .ant-upload-btn {
      padding: 0;
      display: flex;
      justify-content: center;
    }
  }
}

.location_tasks,
.location_common_areas {
  .loc_wrapper {
    width: 708px;
  }
}

button {
  &.no_bg {
    background: transparent !important;
    padding: 0;
  }
  &.p_8 {
    padding: 8px;
  }
  &.green_color {
    color: #0b9f57;

    &:focus,
    &:active,
    &:hover {
      color: #0b9f57;
    }
  }
}

.tooltip_modal {
  z-index: 1071;
}

.black_icon_btn {
  padding: 8px 20px 8px 16px;
  border-radius: 44px;
  color: #fff;
  background: $darkGrey;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &:hover {
    background: #25282f;
    color: #fff;
  }
}

.add_section {
  background: #fbfbfc;
  border-radius: 12px;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  border: 1px solid #f2f3f7;
  width: 100%;

  &.error_section {
    border: 1px solid #e04369;
  }

  .section_icon {
    margin-right: 3px;
    padding: 0 8px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
    width: 442px;

    input.ant-input.formControl {
      background: transparent;
      border: 0 !important;
      padding: 0 !important;
      color: #16181d;
      font-size: 16px;
      font-weight: 500;

      &::placeholder {
        opacity: 1;
        color: #9ca0aa;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  button {
    padding: 8px;
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
  }
}

.black_text_btn {
  color: #16181d;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  &:focus,
  &:hover {
    color: #16181d;
  }
}

.white_icon_btn {
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #e4e5e7 !important;
  color: #16181d;
  border-radius: 44px;
  padding: 8px 20px 8px 16px;

  &:focus,
  &:hover {
    color: #16181d;
  }
}

.green_text_btn {
  color: #0b9f57;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  &:focus,
  &:hover {
    color: #0b9f57;
  }
}

.added_section_listing {
  background: #fbfbfc;
  border-radius: 16px;
  padding: 16px;
  width: 100%;

  .ant-table-thead {
    display: none;
  }

  .white_icon_btn {
    margin: 12px 0 0 0;
  }

  .ant-table {
    background: transparent;

    .ant-input[disabled] {
      border: 0;
      background: transparent;
      padding: 5px 0;
      color: #16181d;
      font-size: 16px;
      font-weight: 500;
      cursor: text;
    }

    .ant-input {
      border: 0;
      background: transparent;
      padding: 5px 0;
      color: #16181d;
      font-size: 16px;
      font-weight: 500;
      cursor: text;
    }

    .btn_wrapper {
      display: none;
    }

    .drag-inner-header {
      width: 100%;
    }

    .ant-col.drag-card-item-text {
      width: calc(100% - 218px);
    }

    .right_btn {
      button {
        margin-left: 8px;
        padding: 8px;
      }

      .btn_wrapper {
        button {
          padding: 0;
        }
      }
    }

    .ant-table-container {
      table {
        > .ant-table-tbody {
          > tr {
            &:hover {
              background-color: transparent !important;
              .btn_wrapper {
                display: block;
              }
            }
            td {
              padding-left: 0;
              border: 0;
              padding-right: 0;
              &:first-child {
                padding-left: 8px;
                padding-right: 8px;
              }
            }
          }
        }
      }
    }

    .drag-inner-header {
      width: 100%;
    }
  }

  .add_section {
    background: transparent;
    border-radius: 0;
    padding: 0;
    border: 0;
    margin-top: 12px;

    .ant-form-item {
      width: 425px;
    }
  }
}

.location_tasks {
  .ant-card {
    cursor: pointer;
    margin-bottom: 16px;
    border: 1px solid #e4e5e7;

    &.selected {
      border: 1px solid #0b9f57;

      .ant-card-meta-title {
        span {
          display: block;
        }
      }
    }

    .ant-card-meta-title {
      display: flex;
      align-items: center;
      line-height: 24px;
      margin-bottom: 4px;

      span {
        margin-left: auto;
        padding: 2px 8px;
        background: #0b9f57;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        border-radius: 24px;
        display: none;
      }
    }

    .ant-card-meta-description {
      color: #505560;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.add_hotel_floor {
  display: flex;
  flex-direction: column;

  .add_floor_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 204px;
    background: transparent;
    border: 1px dashed #e4e5e7 !important;
    color: #16181d;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    height: 68px;

    &:hover {
      background-color: #f2f3f7;
    }
  }

  .floor_section {
    display: inline-flex;
    align-items: center;
    margin-top: 16px;
    width: 100%;
  }

  .floor_box {
    background-color: #16181d;
    color: #fff;
    margin-right: 4px;

    &:hover {
      .f_number_text {
        display: none;
      }
    }
  }

  .f_r_box {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    display: flex;
    position: relative;

    .f_edit_del_btn {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .room_box_info {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;

      .room_credit {
        color: #505560;
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }
      .room_type {
        color: #505560;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        width: 100%;
        margin-top: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .f_number_text {
      width: 100%;
      height: 100%;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }

    .show_on_hover {
      padding: 0;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      color: #fff;

      &.ant-btn {
        .anticon {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 20px;
            height: 20px;
          }
          path {
            fill: #fff;
          }
        }
      }
    }

    .check_box {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &:hover {
      .f_edit_del_btn {
        opacity: 1;
        visibility: visible;
      }

      .room_box_info {
        display: none;
      }
    }

    &.room_box {
      background-color: #f2f3f7;
      margin: 0 4px;

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #0b9f57;
          border-color: #0b9f57;
          border-radius: 2px;
        }
      }

      .f_number_text {
        color: #16181d;
      }

      &.selected_box {
        .f_edit_del_btn {
          opacity: 1;
          visibility: visible;

          .show_on_hover {
            display: none;
          }
        }

        .room_box_info {
          display: flex;
        }

        .room_type,
        .f_number_text,
        .room_credit {
          color: #fff !important;
        }
      }

      &.selected_box,
      &:hover {
        background-color: #25282f;
        color: #fff;
      }
    }
  }

  .add_room_btn {
    border: 1px dashed #e4e5e7 !important;
    border-radius: 16px;
    padding: 24px 10px;
    height: 80px;
    width: 108px;
    background: #fff;
    color: #16181d;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;

    &:hover {
      background-color: #f2f3f7;
    }
  }

  .card_choose {
    h4 {
      &.ant-typography {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .floors_wrapper {
    .floow_boxes_room {
      overflow: auto;
      overflow-y: hidden;
    }
  }
}

.reordering {
  .ant-drawer-body {
    padding: 0;
  }
  .top_drawer_header {
    margin: 0 0 16px 0;
  }
}

.reorder_listing {
  padding: 0 32px;
  height: calc(100vh - 270px);
  overflow: auto;

  h2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #16181d;
    margin: 8px 0;
  }

  .sorting_ul {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.add_floor_option {
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 64px;
}

.reorder_text {
  padding: 16px 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #505560;
}

.sort_list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 8px 8px 8px 2px;
  z-index: 9999;
  position: relative;

  .drag_icon {
    padding: 6px;
    margin-right: 6px;
  }
  .sort_avatar {
    margin-right: 8px;
  }
  .sort_desc {
    h4 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #16181d;
      margin: 2px 0 0 0;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #686d79;
      margin: 0;
    }
  }
}

.ukg_sync_popup {
  h2 {
    color: #16181d;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .ant-collapse-borderless {
    background-color: transparent;

    > .ant-collapse-item {
      background-color: #f0effb;
      border-radius: 12px;
      margin-bottom: 8px;
      border: 0;

      > .ant-collapse-header {
        flex-direction: row-reverse;

        .info_text {
          .ant-typography {
            margin-bottom: 0;
            color: #16181d;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
          }
        }

        .ant-collapse-arrow {
          margin-right: 0;
          svg {
            transform: rotate(90deg);
            transition: 0.5s;
          }
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-arrow {
          svg {
            transform: rotate(270deg) !important;
          }
        }
      }
    }
  }
}

.note_card {
  .ant-card {
    background-color: #f0effb;
    border-radius: 10px;
    margin-bottom: 32px;
    border: 0;

    .ant-card-body {
      padding: 16px;
      display: flex;
      align-items: flex-start;

      .ant-image {
        margin-right: 12px;
      }

      p {
        color: #16181d;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
  }
}

.process_btn {
  .no_bg {
    margin-left: -8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;

    .ant-image {
      margin-left: 8px;
    }
  }
}

.room_service {
  .panel_header {
    position: relative;

    .ant-switch {
      position: absolute;
      top: 13px;
      left: 16px;
      z-index: 9;
    }
    .deleviry_accordian {
      > .ant-collapse-item {
        .ant-collapse-header {
          padding: 16px 4px 16px 76px;
          flex-flow: row-reverse;
          background: #f7f8f9;
          border-radius: 12px;
        }

        .ant-collapse-content {
          > .ant-collapse-content-box {
            padding-top: 24px;
          }
        }
      }
    }
  }

  .formControl {
    border: 1px solid #e4e5e7;
    border-radius: 8px;
    padding: 0 !important;

    .ant-input-number-input {
      padding: 8px 0 8px 12px;
      height: 36px;
    }
  }

  .location_tasks {
    .ant-card-meta-detail {
      width: calc(100% - 86px);
    }

    .specific_select {
      margin-left: 90px;
    }
  }

  .loc_wrapper {
    padding-bottom: 25px !important;
  }
}

.specific_select {
  margin-top: 16px;
  margin-left: 60px;
  width: 207px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select-selector {
    border: 1px solid #e4e5e7;
  }
}

.top_head_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.mt-auto {
  margin-top: auto;
}

.admin_delivery {
  padding-bottom: 25px;
  > .ant-collapse {
    background-color: #fbfbfc;
    margin-bottom: 12px;
    border-radius: 16px;
    border: 0;

    .ant-collapse-expand-icon {
      width: 20px;
      height: 20px;
    }

    .ant-collapse-header-text {
      color: #16181d;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }

    .ant-collapse-item {
      .ant-collapse-header {
        padding: 16px 16px;
      }

      .ant-collapse-content > {
        .ant-collapse-content-box {
          padding-top: 0;
        }
      }

      .ant-collapse-arrow {
        svg {
          transform: rotate(-90deg);
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-arrow {
          svg {
            transform: rotate(0deg);
          }
        }
      }
    }

    .delivery_row {
      background-color: #fff;
      border-radius: 12px;
      padding: 8px 32px 24px;
      margin: 0 16px 16px 16px;

      &.height_drop_auto {
        padding: 16px 32px;
        .delivery_c_header {
          margin-bottom: 0;
          .company_dropdown {
            .ant-select-selector {
              height: 24px;

              .ant-select-selection-search {    
                input {
                  height: 24px;
                  padding: 0px 12px 0px 0 !important;
                }
              }
            }
          }
        }

        .ant-select-selector {
          .ant-select-selection-placeholder {
            line-height: 24px;
          }
        }
      }

      .ant-card {
        background: #f7f8f9;
        border-radius: 12px;

        .ant-card-body {
          padding: 12px 16px;

          img {
            margin-right: 8px;
          }
        }
      }

      &:hover {
        .delivery_c_header {
          .c_right_head {
            display: flex;
          }
        }
      }
    }

    .delivery_c_header {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;

      .company_dropdown {
        .ant-select-selector {
          border: 0;
          padding: 0;
          background: transparent;
          height: 46px;

          .ant-select-selection-search {
            padding: 0;

            input {
              height: 30px;
              padding: 8px 12px 8px 0 !important;
            }
          }
        }

        .ant-select-selection-item {
          color: #16181d;
          line-height: 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          height: 45px;

          .detail_label {
            display: block;
            color: #9ca0aa;
          }
        }
      }

      .c_right_head {
        display: none;
      }

      .c_left_head {
        width: calc(100% - 150px);
      }

      h3 {
        color: #16181d;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 4px;
      }
      p {
        color: #9ca0aa;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
      }
    }

    .deleviry_accordian {
      > .ant-collapse-item {
        .ant-collapse-header {
          padding: 16px 0 15px 60px;
          flex-flow: row-reverse;

          .ant-collapse-expand-icon {
            display: none;
          }
        }
      }
    }

    .delivery_header {
      &.panel_header {
        .ant-switch {
          position: absolute;
          top: 13px;
          left: 0;
          z-index: 9;
        }

        .ant-collapse-item {
          > .ant-collapse-content {
            > .ant-collapse-content-box {
              padding: 16px 0 0 0;

              .task_label {
                color: #16181d;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              }

              .tasks_radio {
                .ant-radio-group {
                  max-width: 100%;
                  background-color: #f7f8f9;
                  border-radius: 16px;
                  padding: 2px;

                  .ant-radio-button-wrapper {
                    width: 50%;
                    border: 0;
                    background: transparent;
                    line-height: 32px;
                    height: 32px;
                    color: #16181d;

                    &.ant-radio-button-wrapper-checked {
                      .ant-radio-button {
                        &::before {
                          display: none;
                        }
                      }
                      span {
                        &::before {
                          content: '';
                          background-color: #0b9f57;
                          width: 9px;
                          height: 9px;
                          border-radius: 100%;
                          margin-right: 8px;
                          display: inline-block;
                        }
                      }

                      &:after {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                        background-color: #fff;
                      }
                    }
                  }
                }
              }

              .delivery_timebox {
                margin-bottom: 0;
                > .ant-form-item-row {
                  flex-direction: column;

                  .ant-form-item-label {
                    justify-content: flex-start;
                  }
                }
              }

              .time_options {
                position: absolute;
                top: 40px;
                width: calc(100% - 33px);
                display: flex;
                align-items: center;
                overflow: hidden;
                overflow-x: auto;
                left: 33px;

                &::-webkit-scrollbar {
                  height: 12px;
                }

                &::-webkit-scrollbar-track {
                  background-color: transparent !important;
                }

                .selected_options {
                  background: #f5f5f5;
                  border-radius: 4px;
                  padding: 1px 0px 2px 8px;
                  display: flex;
                  align-items: center;
                  margin-right: 6px;
                  white-space: nowrap;

                  span {
                    line-height: 20px;
                  }
                }

                .close_btn {
                  z-index: 10;
                  position: relative;
                  padding: 0;
                  background: transparent;
                  padding: 0 5px;
                }

                p {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 21px;
                  color: #b9bbbd;
                  margin: 0;
                }
              }

              .ant-picker-input {
                z-index: 9;
                cursor: pointer;
                height: 100%;

                > input {
                  color: #fff;
                  visibility: hidden;
                  opacity: 0;
                }
              }

              .delivery_time_wrapper {
                .ant-form-item-label {
                  label {
                    color: #16181d;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 8px;
                  }
                }

                .time_options {
                  top: 32px;
                  left: 10px;
                }
              }
            }
          }
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.003em;
      color: #505560;
      margin: 2px 0 22px 0;
    }

    .delivery_timebox {
      .formControl {
        border: 1px solid #e4e5e7;
        height: 36px;
        border-radius: 6px;

        .ant-picker-input {
          flex-direction: row-reverse;

          .ant-picker-suffix {
            margin: 8px 8px 8px 12px;
          }
        }
      }
    }

    .singleCheckbox {
    }

    .task_checkbox {
      .ant-checkbox-group {
        display: flex;
        align-items: center;

        .ant-checkbox-wrapper {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          position: relative;
          justify-content: center;

          &.ant-checkbox-wrapper-checked {
            .ant-checkbox-inner {
              background-color: #16181d;

              &::after {
                display: none;
              }
            }

            .ant-checkbox {
              + span {
                color: #fff;
              }
            }
          }

          .ant-checkbox {
            display: block;
            position: absolute;
            top: 0;
            left: 0;

            + span {
              padding: 0;
              position: relative;
              z-index: 9;
              color: #686d79;
            }
          }

          .ant-checkbox-inner {
            border-radius: 100%;
            border: 1px solid #e4e5e7;
          }

          + .ant-checkbox-wrapper {
            margin-left: 4px;
          }
        }
      }
    }
    .plus_button {
      background: transparent;
      color: #0b9f57;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  .delivery_body {
    margin: 0;
    margin-left: -32px;
    width: calc(100% + 64px);
    padding: 16px 32px;
    border-top: 1px solid #e4e5e7;

    h4 {
      margin-bottom: 0;
      color: #16181d;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: capitalize;
      margin-left: 12px;
    }

    .delivery_timing {
      padding-left: 56px;
    }
  }

  .d_list_head {
    display: flex;
    align-items: center;
    margin: 10px 0 26px 0;

    .ant-switch {
      cursor: inherit;
      opacity: 1;
      border: 1px solid #6bb870;
    }
  }
}

.add_service_div {
  &.add_section {
    .service_dropdown {
      width: calc(100% - 220px);
  
      .ant-select-selector {
        border: 0 !important;
        background: transparent !important;
        padding: 0 5px !important;
        height: auto !important;
  
        .ant-select-selection-search {
          padding: 0 0 0 5px !important;
          input {
            height: 30px !important;
  
            &.ant-select-selection-search-input {
              padding: 8px 12px 8px 0 !important;
            }
          }
        }
      }
    }

    .ant-select-arrow {
      z-index: 0;
    }
  }
}

.category_sidebar {
  h3 {
    color: #16181d;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .category_wrapp {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .input_wrap {
    width: calc(100% - 36px);
    padding-right: 8px;
  }

  .btn_wrap {
    button {
      width: 36px;
      height: 36px;
      border: 1px solid #e4e5e7 !important;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .category_input {
    margin-bottom: 0 !important;

    .formControl {
      padding: 7px 12px !important;
    }
  }

  .add_category_btn {
    width: 100%;
    background-color: #f2fcf7;
    height: 40px;
    padding: 8px 0;
    color: #0b9f57;
    font-weight: 500;

    span {
      font-size: 14px !important;
    }
  }
}

.add_company_sidebar {
  padding-top: 32px;

  h3 {
    color: #16181d;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .ant-upload {
    &.ant-upload-drag {
      border: 0;
    }

    .ant-upload-drag .ant-upload-drag-icon {
      margin-top: 30px;
    }
  }
}

.set_delivery_form {
  .delivery_form_row {
    padding: 0 20px;

    .delivery_header {
      border-bottom: 1px solid #e4e5e7;
      position: relative;
      padding: 0 0 5px 0;
    }

    &:last-child {
      padding: 0;

      .delivery_header {
        border-bottom: 0;
      }
    }
  }
}

.delivery_cards {
  color: #16181d;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.floor_cards {
  .ant-card {
    cursor: pointer;
    .ant-card-body {
      display: flex;

      .floor_titles {
        padding-left: 16px;

        h4 {
          margin-left: 0;
          color: #16181D;
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        h5 {
          color: #686D79;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          margin: 0;

          button {
            color: #0B9F57;
            background: transparent;
            padding: 4px 0;
            line-height: 20px;

            &:hover {
              color: #137646;
            }
          }
        }
      }

      
    }
  }
}

.upload_csv_floor {
  .ant-upload {
    &.ant-upload-drag {
      height: calc(100vh - 275px);
      border: 1px dashed #FBFBFC;

      &:hover {
        border: 1px dashed #D5D7DB;
      }

      .ant-upload {
        padding: 0;
      }

      .floor_drag {
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: center;
        justify-content: center;
        padding: 16px 0;

        .ant-upload-drag-icon {
          margin-top: 0;
        }
      }

      .drawer_error_note {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 612px;
        max-height: calc(100vh - 550px);
        overflow: auto;

        .info_card {
          max-width: 100%;
          width: 100%;
        }
      }

      .error_table {
        width: 100%;

        .error_text {
          background-color: #fff;
          border-radius: 10px;
          margin-bottom: 8px;
          padding: 16px;

          .text_message {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.location_newsidebar_menu {
  &.small_sidebar {
    .user_details {
      .more_options,
      .user_info {
        display: none;
      }
    }
  }
}

// .location_new_sidebar {
//   display: flex;
//   width: calc(100% + 244px);
//   margin-left: -244px;
//   position: relative;
//   z-index: 99;
//   padding-left: 244px;

//   .location_newsidebar_menu {
//     width: 244px;
//     background-color: #16181d;
//     height: 100vh;
//     position: fixed;
//     z-index: 9;
//     padding: 16px;
//     color: #fff;
//     left: 0;
//     top: 0;

//     .navItemLink {
//       .user_details {
//         .only_email {
//           align-items: center;

//           .user_info {
//             h3 {
//               margin-bottom: 0;
//             }
//           }
//         }
//         .user_info {
//           width: 150px;
//         }
//       }
//     }

//     &.small_sidebar {
//       width: 80px;
//     }

//     // .nav_without_hotel {
//     //   height: calc(100vh - 102px);
//     // }
//   }
// }

.back_location {
  padding: 8px 16px !important;
  margin-bottom: 16px;
  color: #fff;

  &:focus,
  &:hover {
    color: #fff;
  }
}

.ant-message {
  bottom: auto;
  top: 40px;
  right: 40px;

  .ant-message-notice {
    padding: 0;
  }

  .ant-message-notice-content {
    background: transparent;
    border-radius: 0;
    color: #16181d;
    padding: 0;
    font-size: 16px;
    box-shadow: none;

    .ant-message-custom-content {
      line-height: 20px;
      border-radius: 12px;
      padding: 7px 14px;
      display: flex;
      align-items: center;
      background: #fff;

      .anticon {
        display: block;
        margin-right: 12px;
      }

      &.ant-message-success {
        border: 1px solid #0b9f57;
      }
      &.ant-message-error {
        border: 1px solid $red;
      }
    }
  }
}

.basket_filter {
  display: flex;
  padding: 0 12px;
  margin-bottom: 8px;

  .rooms_basket {
    display: flex;
    align-items: center;

    h4 {
      color: #1b1c1d;
      font-size: 16px;
      line-height: 19px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .show_hide_filter {
    margin-left: auto;
    color: #7f8385;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
  }
}

.chnage_image_wrapper {
  width: 350px;

  .upload_loc_image {
    margin-bottom: 0 !important;
  }

  .icon_btn {
    .ant-image {
      margin-right: 8px;
    }
  }
}

.editMultipleBar {
  .icon_btn {
    .ant-image {
      margin-right: 8px;
    }
  }
  .red_btn {
    background-color: #e04369;
    border: 1px solid #e04369 !important;
    color: #fff;
  }
  .multi_bar_wrapper {
    background: #16181d;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
  }

  .left_multi {
    .ant-checkbox-wrapper {
      margin-right: 14px;

      span {
        color: #fff;
        line-height: 20px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #0b9f57;
        border-color: #0b9f57;
        border-radius: 2px;
      }
    }
  }

  .right_multi {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    button {
      margin-left: 12px;

      

      &.no_bg {
        border: 0 !important;
        color: #fff;
        padding: 8px 10px !important;
        margin-left: 10px;

        &:first-child {
          margin-left: 5px;
        }
      }
    }
  }
}

.empty_table {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;

  .empty_image {
    margin-bottom: 32px;
  }

  .empty_text {
    color: #686d79;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.show_sections {
  padding: 0 8px 8px;
  width: 100%;

  &:has(.ant-checkbox-wrapper) {
    .no_sections {
      display: none;
    }
  }

  .no_sections {
    font-size: 12px;
    color: #9CA0AA;
    min-height: 22px;

    + .no_sections {
      display: none;
    }
  }

  .ant-checkbox-wrapper {
    background-color: #8578d724;
    padding: 4px;
    border-radius: 4px;
    color: #8578d7;
    line-height: 16px;
    font-size: 12px;
    margin: 2px;
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    height: 22px;

    + .no_sections {
      display: none;
    }

    &:has(> .ant-checkbox.ant-checkbox-checked) {
      background-color: #8578D7;
      color: #fff;
    }

    .ant-checkbox {
      display: none;
      margin-left: 6px;
      top: 0;

      .ant-checkbox-inner {
        background-color: transparent;
        border-color: transparent;
        border: 0;

        &:after {
          border: 1px solid #fff;
          border-top: 0;
          border-left: 0;
          top: 44%;
          width: 5px;
          height: 10px;
        }
      }

      &.ant-checkbox-checked {
        display: block;
      }

      + span {
        padding: 0;
      }
    }

    + .ant-checkbox-wrapper {
      margin-left: 4px;
    }
  }
}

.filters_wrapper {
  height: 0;
  opacity: 0;
  visibility: hidden;

  &.show_filters {
    height: auto;
    opacity: 1;
    visibility: visible ;
  }
}

.complete_set {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .ant-switch {
    margin-right: 16px;
  }

  .complete_text {
    color: #16181d;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    img {
      margin-left: 6px;
    }
  }
}

.staff_scheduling {
  position: relative;

  .staff_form {
    position: initial;

    .ant-input-number-handler-wrap {
      border-radius: 0 10px 10px 0;
    }
  }
}

.staff_scheduling_location {
  position: relative;
  height: 100vh;
  padding: 64px 0 110px 0;

  .staff_wrapper {
    height: 100vh;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .staff_form {
    position: initial;
  }

  .location_btn {
    position: absolute;
  }
}

.floor_inline {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin-top: 14px;
}

.floor_icons {
  display: flex;
  align-items: center;
  height: 20px;

  .add_multiple_rooms {
    width: 28px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    cursor: pointer;
    margin-left: -19px;

    &:hover {
      button {
        display: block;
      }

      &:after {
        display: block;
      }
      
    }

    button {
      padding: 0;
      background: transparent;
      display: none;
    }

    img{
      position: relative;
      z-index: 9;
    }

    &:after {
      content: '';
      position: absolute;
      left: 14px;
      width: 2px;
      height: 100%;
      background-color: #0B9F57;
      display: none;
    }
  }
}

.full_layout_loader {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99;
  >div {
    background-color: #ffffffb0;
  }
}

.mews_logo {
  img {
    object-fit: contain;
  }
}

.mews_logo_wrapper {
  width: 62px;
  height: 62px;
  background: #f7f8f9;
  border-radius: 20px;
  padding: 10px;
}

.ant-table-wrapper {
  .ant-spin-nested-loading {
    > div {
      > .ant-spin {
        max-height: 500px;

        .ant-spin-dot {
          font-size: 60px;
          width: auto;
          height: auto;
        }
      }
    }
  }
  
}