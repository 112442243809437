.helpcenter_section {
  width: 100%;
  background-color: #f5f5f5;
  padding-top: 64px;
  min-height: 100vh;
  position: relative;
  .ant-card {
    margin: 16px;
  }
  .card_wrapper {
    // display: flex;
    padding: 0 24px;
    width: 100%;
    padding-top: 8px;
    &h4.ant-typography {
      margin-top: 3.5px;
      margin-bottom: 27.5px;
      font-weight: 700;
    }
    .card-title {
      margin-top: 3.5px;
      margin-bottom: 27.5px;
      font-weight: 700;
    }
    &div.ant-typography {
      margin-bottom: 32px;
      color: rgb(127, 131, 133);
    }
    .card-paragraph {
      // margin-bottom: 32px;
      color: rgb(127, 131, 133);
      margin:0;
    }
    .card {
      margin-bottom: 33px;
      width: 100%;
      .card_video {
        border-radius: 8px;
        width: 100%;
        // width: 30vw;
        height: 225px;
      }
      h4.ant-typography {
        margin-top: 16.28px;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 14px;
        // font-size: 18px;
        letter-spacing: 0.003em;
        line-height: 140%;
        color: #1B1C1D;
      }
      div.ant-typography {
        color: rgb(127, 131, 133);
        font-size: 14px;
        font-weight: 400;
      }
    }
    .req_collapse{
      // width: 480px;
      margin-bottom: 32px;
      margin-top: 32px;

      h2.ant-typography {
        margin-bottom: 0;
      }
    }
    h2.ant-typography {
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.003em;
      margin-bottom: 17px;
    }
    

    .ant-collapse {
      > .ant-collapse-item {
        > .ant-collapse-header {
          flex-direction: row-reverse;
          align-items: center;
          padding-left: 0;
          padding-right: 0;
  
          .ant-collapse-expand-icon {
            padding-top: 4px;
          }
  
          .ant-collapse-arrow {
            margin: 0 0 0 10px;
            transition: 0.5s;
          }
        }
  
        &.ant-collapse-item-active {
          .ant-collapse-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }

    .ant-collapse {
      border-bottom: 1px solid #dbdddf;
      border-radius: 0;
  
      &:last-child {
        border-bottom: 0;
      }

      .inner-table,
      .drag-upper-header {
        .right_btn {
          opacity: 0;
          height: 35px;
        }
      }

      tr {
        .drag-upper-header {
          &:hover {
            .right_btn {
              opacity: 1;
            }
          }

          &:hover {
            border-radius: 6px;
            &:hover {
              background: rgba(185, 187, 189, 0.14) !important;
            }
          }
        }
        
      }

      .ant-collapse-header {
        padding: 16px 0;
        .ant-collapse-header-text {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $blackColor;
          letter-spacing: 0.003em;
        }
        .ant-collapse-expand-icon {
          padding-top: 0 !important;
        }
      }

    
      .ant-collapse-content-box {
        padding: 0 !important;

        .row-card {
          margin-bottom: 12px;
      
          .setting-btn {
            padding: 9px 12px;
            background: rgba(185, 187, 189, 0.22);
            border-radius: 6px;
            font-size: 13px;
            line-height: 16px;
            color: #7F8385;
            font-weight: 500;
            height: 34px;
            display: flex;
      
            .plus-icon {
              color: #7f8385;
              font-size: 15px !important;

              + span {
                font-size: 13px !important ;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.help-support {
  padding: 26px 29px;
  gap: 10px;
  background: rgba(107, 184, 112, 0.14);
  border-radius: 16px;
  // width: 337px;
  // margin-top: 19px;
  // margin-left: -8px;
  // width: fit-content;
  width: 500px;
  // top: 32px;
  // right: 48px;
  // position: absolute;

  .h3 a{
    color: #6bb870;
    font-size: 18px;
    line-height: 22px;
    margin-top: -6px;
    // margin-bottom: -10px;
  }
}

