.workshift_sidebar {
  top: 0;
  right: 0;
  width: 22%;
  height: 100%;
  display: flex;
  padding: 32px 0 0;
  position: fixed;
  z-index: 9;
  background: #F7F8F9;
  flex-direction: column;
  transition: .5s;

  

  .workshifts_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    padding: 0 32px;

    @media (max-width: 1367px) {
      padding: 0 15px;
    }

    h3 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 0;
      white-space: nowrap;
    }

    button {
      line-height: 16px;
      font-weight: 500;
      margin-bottom: 0;
      color: #0B9F57;
      font-size: 12px;
      display: flex;
      align-items: center;
      padding: 7px 4px;
      background: transparent;

      span {
        margin-left: 6px;
      }
    }
  }

  .sidebar_calendar {
    padding: 0;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 32px;

    @media (max-width: 1367px) {
      padding: 0 15px;
    }

    .icon_next {
      width: 36px;
      height: 36px;
      border-radius: 44px;
      border: 1px solid #E4E5E7 !important;
      background: #fff;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .5s;

      .ant-image {
        display: flex;
      }

      &:hover {
        background: #E4E5E7;
      }
    }

    .prev_next {
      transform: rotate(180deg);
    }

    .side_calendar_style {
      margin: 0 8px;
      background: #fff;
      border: 1px solid #E4E5E7;
      border-radius: 8px;
      width: calc(100% - 88px);
      height: 36px;

      @media (max-width: 1420px) {
        .ant-picker {
          padding: 4px 8px 4px;
          .ant-picker-input {
            > input {
              font-size: 13px;
            }
          }
        }
      }
      @media (max-width: 1367px) {
        margin: 0 4px;
        width: calc(100% - 80px);
      }
    }
  
    .ant-picker {
      background: transparent;
      border: 0;
      width: 100%;
      height: 36px;
    }

    .ant-picker-input {
      flex-direction: row-reverse;
    }
  
    .ant-picker-suffix {
      margin-right: 8px;
      margin-left: 0;

      @media (max-width: 1367px) {
        margin-right: 4px;
      }
    }
  }

  .workshift_footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 22%;
    border-top: 1px solid #E4E5E7;
    padding: 21px 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 0;
      color: #16181D;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      background: #fff;
      border: 1px solid #E4E5E7 !important;
      width: 260px;
      justify-content: center;
      border-radius: 44px;

      > .anticon {
        font-size: 16px !important;

        + span {
          font-size: 14px !important;
          margin-left: 6px;
        }
      }
    }
  }

  .shift_wrapper {
    width: calc(100% + 11px);
    overflow: auto;
    padding-right: 10px;

    .scrollbar_div {
      max-height: calc(100vh - 240px);
    }

    &.shift_wrapper_full {
      height: calc(100vh - 138px);
      .scrollbar_div {
        max-height: calc(100vh - 138px);
      }
    }

    

    .shift_box {
      padding: 16px 22px 4px 32px;
      width: calc(100% - 11px);

      @media (max-width: 1367px) {
        padding: 16px 5px 4px 15px;
      }
    }

    .shifts_name_color {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .shifts_color {
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 1px;
      }

      h4 {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
    }

    .shift_person_info {
      .ant-card {
        margin-bottom: 4px;
        background-color: #fff;
        border-radius: 8px;
        overflow: visible;

        .ant-card-body {
          padding: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            .edit_dots {
              visibility: visible;
              margin-left: 0;
              opacity: 1;
            }

            .dots_hover {
              .card_image {
                display: none;
              }
            }
            
          }
        }

        .ant-card-meta {
          margin: 0;
          width: calc(100% - 28px);

          .ant-card-meta-avatar {
            padding-right: 8px;
          }

          .ant-card-meta-detail {
            .ant-card-meta-title {
              margin-bottom: 2px;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #16181D;
            }

            .ant-card-meta-description {
              margin-bottom: 0;
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              color: #686D79;
              display: flex;
              align-items: center;
              white-space: nowrap;
              flex-wrap: wrap;
              >span {
                margin-right: 4px;
                margin-bottom: 2px;
              }
            }

            .ant-tag {
              margin-bottom: 0;
              font-size: 10px;
              line-height: 16px;
              font-weight: 500;
              color: #16181D;
              width: 85px;
              height: 17px;
              border: 1px solid #9CA0AA;
              border-radius: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
            }
          }
        }

        .right_d_card {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          position: relative;
        }

        .card_image {
          width: 22px;
        }

        .edit_dots {
          visibility: hidden;
          margin-left: 20px;
          transition: .5s;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 28px;
        }
      }
    }
  }
}

.no_shifts_today {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;

  .no_shifts_image {
    width: 69px;
    height: 69px;
    background-color: #F2F3F7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 22px;
  }

  .empty-text {
    color: #9CA0AA;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .create-shift-link {
    background-color: transparent;
    color: #0B9F57;
    padding: 8px;
    font-size: 14px;
    font-weight: 500px;
    line-height: 20px;
  }
}

.initials_avatar_small {
  border: 1px solid #E4E5E7;
  background: transparent;
  color: #505560;
  font-size: 10px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials_avatar_big {
  border: 1px solid #E4E5E7;
  background: transparent;
  color: #fff;
  font-size: 12px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}