$green: #0B9F57;
$blackColor: #1B1C1D;
$red: #F37066;
$blue: #5E95E7;
$red-hover:#E15348 ;
$blue-hover: #467BCA;
$green-hover:#018545 ;
$placeholder:#7F8385;
$silverlight:rgba(185, 187, 189, 0.14);
$silvermedium:rgba(185, 187, 189, 0.4);
$close-hover:#F5F5F5;
$grey: rgba(185, 187, 189, 0.22);;
$grey-hover: $silvermedium;