.delivery_service {
    margin-top: 0 !important;
    .ant-form-item {
        .formControl {
            height: 40px;
        }
    }

    .ant-collapse-header-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #1B1C1D;
    }

    .delivery_timebox {
        margin-bottom: 0;

        label {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        }

        .ant-picker-suffix {
          display: none;
        }
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.003em;
        color: #7F8385;
        margin: 2px 0 22px 0;
    }

    .singleCheckbox {
        margin-bottom: 32px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      cursor: auto;
    }

    .panel_header {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0;

        &.delivery_header {
            border-bottom: 1px solid #DBDDDF;
            margin-top: 7px;
        }
      
        .ant-switch {
          position: absolute;
          top: 13px;
          left: 0;
          z-index: 9;
        }
      
        .deleviry_accordian {
          width: 100%;
      
          > .ant-collapse-item { 
            .ant-collapse-header {
              padding: 16px 0 15px 60px;
              flex-flow: row-reverse;
              
        
              .ant-collapse-arrow {
                margin-right: 0;
                transition: 0.5s;
              }
            }
      
            &.ant-collapse-item-active {
              .ant-collapse-arrow {
                transform: rotate(180deg);
              }
            }

            .ant-collapse-content {
                margin-top: 16px;
            }
          }
      
          
      
          .ant-collapse-content-box {
            padding: 0;
          }
      
          .tasks_radio {
            .ant-radio-group {
              max-width: 100%;
            }
          }
        }
      }

      .time_options {
        position: absolute;
        top: 28px;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        overflow-x: auto;
        padding-right: 25px;

        &::-webkit-scrollbar {
          height: 12px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent !important;
        }
        
        .selected_options {
            background: #F5F5F5;
            border-radius: 4px;
            padding: 1px 0px 2px 8px;
            display: flex;
            align-items: center;
            margin-right: 6px;
            white-space: nowrap;
        }

        .close_btn {
            z-index: 10;
            position: relative;
            padding: 0;
            background: transparent;
            padding: 0 5px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #B9BBBD;
        }
      }

      .ant-picker-input {
        z-index: 9;
        cursor: pointer;
        height: 100%;

         > input {
            color: #fff;
            visibility: hidden;
            opacity: 0;
         }
      }
}

.multi_time_custom {
    position: relative;
    width: 100%;
}

.month_info_wrapper {
  display: flex;
  margin-bottom: 16px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .onDays_wrapper {
    width: 326px;

    .task_label {
      display: block;
      margin-bottom: 8px;
    }

    .task_checkbox {
      .ant-checkbox-wrapper {
        width: 36px;
        height: 36px;

        .ant-checkbox-inner,
        .ant-checkbox {
          width: 100%;
          height: 100%;
        }
      }

      .ant-checkbox-checked::after {
        width: 36px !important;
        height: 36px !important;
      }
    }
  }

  .delivery_time_wrapper {
    margin-left: 24px;
    width: 186px;
  }

  .delete_time {
    margin-left: 16px;
    margin-top: 13px;
  }
}

.week_info_wrapper {
  display: flex;
  margin-bottom: 8px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .onDays_wrapper {
    width: 248px;

    .task_label {
      display: block;
      margin-bottom: 8px;
    }

    .task_checkbox {
      .ant-checkbox-wrapper {
        width: 36px;
        height: 36px;

        .ant-checkbox-inner,
        .ant-checkbox {
          width: 100%;
          height: 100%;
        }
      }

      .ant-checkbox-checked::after {
        width: 36px !important;
        height: 36px !important;
      }
    }
  }

  .delivery_time_wrapper {
    margin-left: 16px;
    width: 248px;

    .ant-picker-suffix {
      display: none;
    }
  }

  .delete_time {
    margin-left: 16px;
    margin-top: 24px;

    .ant-btn {
      border: 1px solid #E4E5E7 !important;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      &:hover {
        border-radius: 100%;
      }
    }
  }
}

.plus_button {
  background: rgba(185, 187, 189, 0.22);
  border-radius: 6px;
  padding: 9px 12px;

  img {
    margin-right: 8px;
  }

  span {
    font-size: 13px;
    line-height: 16px;
  }

  &:focus,
  &:hover {
    color: #53595C;
    background: rgba(185, 187, 189, 0.4);
  }
}

.ant-tooltip-inner {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  padding: 12px;
  background: #16181D;
  border-radius: 8px;

  p {
    margin-bottom: 2px;
  }

  .ant-btn {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding: 8px 8px 0 8px;
    margin-left: -8px;

    .ant-image {
      margin-left: 8px;
    }
  }
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #16181D;
}