.reports_section {
  width: 100%;
  min-height: 100vh;
  padding: 40px;
  .heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #16181d;
    margin-bottom: 24px;
  }
  .section_header {
    box-shadow: none;
    left: 244px;
    height: auto;
    padding: 40px 0 0 40px;

    .heading {
      padding-left: 0;

      .header-heading {
        font-size: 28px;
        font-weight: 500;
      }
    }
  }

  .ant-card {
    margin: 16px;
  }
  .ant-card-body {
    flex-direction: column;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    .card-div {
      padding-bottom: 13px;
    }

    @media (max-width: 1366px) {
      padding: 15px;
    }
  }

  .report-button-card {
    min-height: 216px;
    padding: 0;
    width: 100%;
    height: 100%;
    :hover {
      .card-title {
        color: #6bb870 !important;
      }
    }
    .card {
      margin: 0;
      height: 100%;
      border: 1px solid #E4E5E7;
      border-radius: 8px;

      .card-title {
        font-weight: 500;
        color: #16181D;
        width: 100%;
        white-space: initial;
        line-height: 28px;
        font-size: 20px;
        text-align: left;
        margin-bottom: 8px;

        @media (max-width: 1366px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .card-paragraph {
        font-weight: 400;
        width: 100%;
        white-space: initial;
        font-size: 12px;
        line-height: 20px;
        color: #505560;
        text-align: left;
      }
    }
  }
}

.productivity-report-daterange {
  .ant-picker {
    padding: 4px 30px;
    margin-bottom: 29px;
    display: flex;
  }
}

.report-date {
  .ant-form-item-control-input-content {
    background: rgba(185, 187, 189, 0.22);
    border-radius: 6px;
    padding: 6px 10px 7px 12px;
    .formControl {
      border-bottom: 0;
    }
  }
}

.custom_loader {
  position: relative;
  min-height: 68px;

  .loaderImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-spin-container {
    opacity: 1;

    &:after {
      display: none;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    top: 0%;
    left: 38%;
    margin: -6px 0 0 0;
    font-size: 80px;
    color: #6bb870;
  }
}

.popup_text_loader {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7f8385;
  text-align: center;
  margin-top: 29px;
}

.inner-report-section {
  padding: 40px 40px 12px;
  background: #ffffff;
  border-radius: 12px;
  height: 100%;

  .main-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    .ant-btn {
      background: none;
      color: #16181d;
      padding: 0;
      margin-bottom: 12px;
      display: flex;
      align-items: center;

      .ant-image-img {
        padding-right: 15px;
        display: block;
      }

      .label_name {
        color: #16181D;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
      }
    }
  }

  .location_dropdown {
    border: 1px solid #e4e5e7;
    border-radius: 8px;
    height: auto;
    display: flex;
    align-items: center;
    color: #505560;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    position: relative;
    padding: 5px 0 5px 10px;

    > svg {
      width: 25px;
      height: 20px;

      path {
        fill: #16181D;
      }
    }

    .label_dropdown {
      white-space: nowrap;
      color: #16181D;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
    }

    .filter_dropdown.location_filter {
      padding-left: 4px;
      width: 154px;

      .ant-select-selector {
        height: auto;
        border: 0;
        padding: 0;

        .ant-select-selection-item {
          font-weight: 500;
          color: #16181d;
          font-size: 14px;

          .ant-select-selection-item-content {
            width: 98px;
          }
          .anticon-close {
            width: auto;
            height: auto;
            padding: 0;
          }
        }
        .ant-select-selection-search-input {
          height: 20px;
        }
      }

      .ant-select-arrow {
        right: 10px;
        top: 14px;
      }
    }
    .ant-select-multiple .anticon {
      width: auto;
      height: auto;
      padding: 0;
    }

    .ant-select-multiple {
      .ant-select-selection-placeholder {
        color: #16181d;
        left: 5px;
      }

      .ant-select-selector {
        .ant-select-selection-overflow {
          max-width: 104px;
          overflow: hidden;
          flex-wrap: nowrap;
        }
      }
    }
  }

  .export-btn {
    display: flex;
    justify-content: end;

    .ant-btn {
      background: #ffffff;
      border: 1px solid #e4e5e7 !important;
      border-radius: 44px;
      padding: 8px 20px 8px 16px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      height: 36px;

      > .anticon + span {
        font-size: 14px !important;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
    .ant-btn-link,
    .ant-btn-link {
      color: #16181D;
    }

    .ant-btn:hover,
    .ant-btn:focus {
      border: 1px solid #D5D7DB !important;
    }
  }

  .sub-heading {
    color: #505560;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
  }

  .custom-past-range {
    height: 40px;
    background: #f7f8f9;
    border-radius: 8px;
  }

  .ant-collapse {
    > .ant-collapse-item { 
      .ant-collapse-arrow {
        transition: all .5s;
      }
      &.ant-collapse-item-active {
        .ant-collapse-arrow {
          transform: rotate(180deg);
        }
      }  
    }

    .ant-collapse-header {
      display: flex;
      flex-direction: row-reverse;
      padding: 0 !important;
      color: #16161D;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
      padding-right: 16px !important;
  
      .ant-collapse-arrow {
        margin-right: 0 !important;
        display: block !important;
      }
    }
  }

  
  .ant-collapse-content {
    margin-top: 8px;
    padding-right: 0 !important;
    p {
      color: #16181D;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 12px;

      &.label_numb {
        color: #505560;
        margin-bottom: 4px;
      }
    }
    > .ant-collapse-content-box {
      height: 100%;
      padding: 0;
    }
  }

  .collapse {
    border: 1px solid #f7f8f9;
    border-radius: 12px;
    background: #FBFBFC;
    width: 100%;
    padding: 16px 0px 16px 16px;
    

    .input-number {
      height: 36px;
      border: 0;
      background: transparent;

      .ant-input-number-group {
        background: #fff;
        border: 1px solid #E4E5E7;
        border-radius: 8px;
        

        .ant-input-number-input {
          height: 36px;
          padding: 10px;
          color: #16181D;
        }
      }
      .ant-input-number-group-addon {
        background: transparent;
        border: 0;
        color: #16181D;
      }
    }

    &.request {
      .panel_ins {
        .checkbox_wrapper {
          .checklist-groups {
            .user-name {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }

    .panel_ins {
      display: flex;
      flex-direction: column;
      height: 100%;

      .checkbox_wrapper {
        overflow: auto;
        overflow-x: hidden;
        display: flex;
        justify-content: space-between;
        margin-left: 0 !important;
        margin-bottom: 14px;
        margin-top: 10px;
        padding-right: 16px;

        .ant-form-item {
          width: 100%;
          padding: 0 0 16px 0;
          margin-bottom: 0;

          .ant-checkbox-group {
            width: 100%;
            padding-right: 2px;
          }
        }

        .checklist-groups {
          margin-bottom: 14px;
          display: flex;
          margin-left: 0;
          width: 100%;
          position: relative;

          span {
            color: #16181D;
            font-weight: 400;
            font-size: 12px;
          }

          span:not([class]):last-child {
            padding-left: 0;
            padding-right: 22px;
            width: 100%;
          }

          span.ant-checkbox:first-child {
            display: flex;
            right: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 19px;
            margin: auto;
            
            .ant-checkbox-input {
              width: 19.2px;
              height: 19.2px;
            }
            .ant-checkbox-inner {
              width: 19.2px;
              height: 19.2px;
              border-radius: 6px;
              border-color: #0b9f57;

              &:after {
                top: 44%;
                left: 26%;
                width: 4.714286px;
              }
            }
          }

          .ant-checkbox-checked {
            &:after {
              border-radius: 6px;
              border: 1px solid #0b9f57;
            }
          }
          span.ant-checkbox-checked:first-child {
            .ant-checkbox-inner {
              background-color: #0b9f57;
            }
          }
          .ant-checkbox:hover::after,
          .ant-checkbox-wrapper:hover .ant-checkbox::after {
            border-radius: 6px;
          }

          .ant-checkbox {
            +span {
              display: flex;
              align-items: flex-start;
            }
          }

          .user-icon {
            margin-top: 2px;
            margin-right: 8px;
            width: 28px;
            display: inline-block;

            .ant-avatar {
              display: block;
            }
            svg {
              width: 28px;
              height: 28px;
            }
          }
          .user_info {
            width: calc(100% - 40px);
            display: inline-block;
          }
          .user-name {
            color: #16161d;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 2px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .user-location {
            color: #505867;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .selectall {
        margin: auto 16px 0 0;
        background: #16161d;
        border-radius: 8px;
        padding: 8px 12px;

        .ant-checkbox-wrapper {
          color: #ffffff;
          position: relative;
          width: 100%;

          span.ant-checkbox {
            position: absolute;
            right: 0;
            top: 1px;
          }

          span:not([class]):last-child {
            width: 200px;
            padding-left: 0;
          }

          .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: #0b9f57;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #0b9f57;
          }
        }

        .ant-checkbox-input {
          width: 19.2px;
          height: 19.2px;
        }

        .ant-checkbox-inner {
          border-color: #0b9f57;
          border-radius: 6px;
          width: 19.2px;
          height: 19.2px;
        }
      }
    }
  }

  .requestType {
    .ant-checkbox {
      top: 0;
    }
    .ant-checkbox-group {
      width: 100%;
      padding-right: 16px;
      display: flex;
      flex-direction: column;

      .ant-checkbox-wrapper {
        position: relative;
        margin: 3px 0;

        span.ant-checkbox:first-child {
          display: flex;
          right: 0;
          position: absolute;
          top: 2px;
          
          .ant-checkbox-input {
            width: 19.2px;
            height: 19.2px;
          }
          .ant-checkbox-inner {
            width: 19.2px;
            height: 19.2px;
            border-radius: 6px;
            border-color: #0b9f57;

            &:after {
              top: 44%;
              left: 26%;
              width: 4.714286px;
            }
          }
        }

        .ant-checkbox-checked {
          &:after {
            border-radius: 6px;
            border: 1px solid #0b9f57;
          }
        }
        span.ant-checkbox-checked:first-child {
          .ant-checkbox-inner {
            background-color: #0b9f57;
          }
        }
        .ant-checkbox:hover::after,
        .ant-checkbox-wrapper:hover .ant-checkbox::after {
          border-radius: 6px;
        }
      }
    }
    .ant-checkbox-wrapper {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-left: 0;

      .ant-checkbox + span {
        padding-left: 0;
        width: 100%;
        color: #16181D;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .requestType-checkbox {
      .ant-checkbox-input {
        width: 19.2px;
        height: 19.2px;
      }
      .ant-checkbox-inner {
        width: 19.2px;
        height: 19.2px;
        border-radius: 6px;
        border-color: #0b9f57;
      }
    }
  }

  .room-cleaning {
    .ant-collapse-content-active {
      // height: 325px;
      height: calc(100vh - 390px);
    }
    .ant-motion-collapse-enter {
      height: 318px !important;
      overflow: hidden !important;
    }
    .ant-motion-collapse-leave-start {
      overflow: hidden !important;
      height: 318px !important;
    }
  }

  .room_rating {
    .ant-collapse-content-active {
      height: calc(100vh - 183px);
    }
  }
  .request {
    .ant-collapse-content-active {
      height: calc(100vh - 359px);
    }
    .ant-motion-collapse-enter {
      height: 318px !important;
      overflow: hidden !important;
    }
    .ant-motion-collapse-leave-start {
      overflow: hidden !important;
      height: 318px !important;
    }
  }

  .request2 {
    .ant-collapse-content-active {
      height: calc(100vh - 510px);
    }
  }

  .room_rating {
    width: 100%;
    .ant-collapse-content-active {
      height: calc(100vh - 202px);
    }
  }

  .productivity {
    width: 100%;
    .ant-collapse-content-active {
      height: calc(100vh - 202px);
    }
    .ant-collapse-header-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #16161D;
    }
    &.ant-collapse {
      > .ant-collapse-item { 
        .ant-collapse-arrow {
          transition: all .5s;
        }
        &.ant-collapse-item-active {
          .ant-collapse-arrow {
            transform: rotate(180deg);
          }
        }  
      }
    }
   
  }

  .user-checkbox {
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: #0b9f57;
      background-color: #0b9f57;
    }
  }
}

.bygroup-radio {
  .radio.time-button {
    color: #686d79;
    padding: 6px 8px;
    font-weight: 500;
    margin-right: 0;

    span {
      vertical-align: middle;
      white-space: nowrap;
      padding: 0;
    }
    .ant-radio {
      display: none;
    }
  }
  label.ant-radio-wrapper.ant-radio-wrapper-checked.radio.ant-radio-circle.time-button {
    background-color: #f2fcf7;
    border-radius: 6px !important;
    color: #0b9f57;
    display: inline-flex;
  }
}

.report-rangepicker {
  .ant-picker {
    border: 1px solid #e4e5e7;
    border-radius: 8px;
    background: #ffffff;
    padding: 8px 6px;

    .ant-picker-range-separator {
      padding: 0 5px;
    }
  }
  .ant-picker-range {
    .ant-picker-input {
      order: 2;
      padding-right: 8px;
      &:after {
        content: '-';
        color: #16181D;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .ant-picker-range-separator {
      order: 3;
    }
    .ant-picker-range-separator ~ .ant-picker-input{
      order: 4;
      padding-right: 0;
      &::after {
        display: none;
      }
    }
    .ant-picker-active-bar {
      display: none;
    }
    .ant-picker-suffix {
      order: 1;
      margin-left: 0;
      margin-right: 4px;
    }
  }

  .ant-picker-suffix {
    &:after {
      content: 'Period: ';
      color: #505560;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 6px;
    }
  }

  .ant-picker-range-separator {
    display: none;
  }
}

.ant-picker-dropdown-range {
  .ant-picker-panel-container {
    margin-left: 0px;
    display: flex;
    flex-direction: row-reverse;

    .ant-picker-footer {
      min-width: auto;
      border-right: 2px solid #e4e5e7;

      .ant-tag-blue {
        background: none;
        color: black;
        text-align: left;
      }
    }
  }
}

.chart_tooltip {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  min-width: 136px;

  h2 {
    color: #fff;
    font-size: 13px;
    margin-bottom: 0;
  }

  span {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }

  .li_div {
    margin-bottom: 0;

    span {
      color: #9CA0AA;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
  }


  .base_div {
    margin-bottom: 6px;
    border-bottom: 1px solid #fff;
    padding-bottom: 6px;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.request-type-checkbox {
  margin-bottom: 0;
  // .ant-checkbox-group {
  //   .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  //     margin-left: 0;
  //     margin-top: 16px;
  //   }
  //   .ant-checkbox-wrapper {
  //     position: relative;
  //   display: flex;
  //   flex-direction: row-reverse;
  //   }

  //   .ant-checkbox-wrapper:nth-child(1) span:nth-child(2) {
  //     left: 0;
  //   position: absolute;
  // }

  //   .ant-checkbox-wrapper span:nth-child(2) {
  //     padding-left: 0;
    
  //   }
  //   .ant-checkbox-wrapper span:nth-child(1) {
  //   position: absolute;
  //   right: 0;
  //   left: 220px;

  //   .ant-checkbox-input {
  //     width: 19.2px;
  //     height: 19.2px;
  //   }

  //   .ant-checkbox-inner {
  //     width: 19.2px;
  //   height: 19.2px;
  //   border-radius: 6px;
  //   border-color: #0b9f57;
  //   }
   
  //   }

  //   .ant-checkbox-wrapper .ant-checkbox-checked {
  //     .ant-checkbox-inner {
  //       background-color: #0b9f57;
  //     }
  //   }
  // }
}



.report_full_height {
  height: 100vh;
  overflow: hidden;
}

.ant-checkbox-checked::after {
  border: none;
}

.margin-error {
  .ant-collapse-content-box {
    padding: 0 16px 0 0 !important;
  }
}

.ant-collapse-content-box {
  p {
    margin-bottom: 12px;
  }
}

.chart_height {
  height: calc(100vh - 214px);

  @media (max-width: 1373px) {
    height: calc(100vh - 259px);
  }
}

.chart_height2 {
  height: calc(100vh - 214px);

  @media (max-width: 1561px) {
    height: calc(100vh - 259px);
  }
}

.error_number {
  .input-number {
    .ant-input-number-group {
      border: 1px solid #E04369 !important;
    }
  }
}

.optional-label {
  position: absolute;
  right: 0;
  top: -22px;
  font-size: 12px;
  color: #9CA0AA;
}

.pdf_exporting {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      &:after {
        content: '\2713';
        border: 0;
        color: #fff;
        top: 3px !important;
        left: 6px !important;
        font-size: 11px;
        transform: rotate(12deg);
      }
    }
  }
}

.user_role_wrapper {
  display: flex;
  margin-top: -2px;
  align-items: center;

  .user-location {
    width: auto !important;
  }

  .tooltip_location {
    margin-left: 4px;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #E6F9F0;
    background: #F2FCF7;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      font-size: 12px;
      color: #0B9F57 !important;
      font-weight: 500 !important;
      white-space: nowrap;
      padding: 0 !important;
    }
  }
}