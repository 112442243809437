@media (max-width: 1499px) {
  .ant-card-body {
      padding: 15px;
  }

  .dashboard_section .Task_Card .section_header {
      padding: 0 0 0 14px;
  }

  .dashboard_section .taskCardStatus .ant-card .ant-card-body {
      padding: 11px 12px 11px 12px;
  }

  .dashboard_section .content .contentWrapper1 .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
      font-size: 18px;
  }

  .delivery_service_card .ant-card-body {
      padding: 12px 15px 20px;
  }

  .delivery_service_card .empty_no_delivery {
      margin: 55px 0px;
  }

  .section_header .heading .header_heading {
      margin-right: 8px;
  }

  .section_header .filter_box {
      margin-left: 8px;
  }

  .section_header .filter_dropdown {
      width: 162px;
  }

  .checklist_filters_left {
      .role_dropdown {
          margin-right: 6px;
      }

      .new_date_filter {
          width: 160px;
          padding: 8px 8px;
      }
  }

  .checklist_filters {
      .checklist_filters_right {
          .filter_btns {
              margin-left: 6px;

              .icon_btn {
                  padding: 8px 12px 8px 12px;
                  font-size: 13px;
              }
          }
      }
  }

  .new_theme {
      padding-left: 20px;
      padding-right: 20px;
  }

  .new_theme .new_header .header_heading {
      padding-left: 20px;
  }

  .report_section .ant-card-body {
      padding: 10px;
  }

  .report_section .report-button-card .card .card-title {
      font-size: 15px;
  }
}


@media (max-width: 1360px) {
  .checklist_filters_left {
      >.role_multi {
          .role_dropdown {
              &:first-child {
                  width: 150px;

                  .ant-select {
                      width: 105px;
                  }
              }
          }
      }

      .new_date_filter {
          width: 150px;
      }
  }
}